import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { useUserProvider } from '@/context/UserProvider';

const MeetPlanbowlContent = () => {
  const { t } = useTranslation('common');
  const { onOpenSelectReportsAlert, onGetStartedClick, onOpenLoginModal, isLogin } =
    useUserProvider();

  const dataList = [
    {
      title: t('AI + knowledge base'),
      desc: t(
        'Base processes your input, including geographic location and family situation, to create a personalized emergency guide.',
      ),
    },
    {
      title: t('Guide Creation'),
      desc: t('Custom guide generation in under 1 minute.'),
    },
    {
      title: t('Always Editable'),
      desc: t(
        'easy to save&printable, and available in digital format with a one-time purchase for lifetime access and updates.',
      ),
    },
  ];

  return (
    <div className={styles.backContent}>
      <div className={styles.content}>
        <div className={styles.topInfoContainer}>
          <p>{t('Powered by AI')}</p>
          <p>{t('Meet Planbowl: Build an Emergency Guide in Minutes')}</p>
          <p>{t('Select your location and family details—PlanBowl will handle the rest.')}</p>
        </div>
        <div className={styles.midInfoContainer}>
          <div className={styles.left}>
            <img src="/images/planbowl/home-icon-5.webp" alt="" />
            <div className={styles.leftInfo}>
              {dataList?.map((item, index) => {
                return (
                  <div key={item?.title}>
                    <p>{item?.title}</p>
                    <p>{item?.desc}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.right}>
            <p>{t('From accidents to disasters and wars')}</p>
            <p>
              {t(
                'PlanBowl offers a customized Emergency Preparedness Guide for you and your loved ones.',
              )}
            </p>
          </div>
        </div>
        <div className={styles.bottomInfoContainer}>
          <span>{t('Build Your Own Guide Now !')}</span>
          <span
            onClick={() => {
              if (isLogin) {
                onOpenSelectReportsAlert(true);
              } else {
                onGetStartedClick('Single');
                onOpenLoginModal(true);
              }
            }}
          >
            {t('Get Started')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MeetPlanbowlContent;
